<template>
  <div class="moniPane">
    <div>
      <div class="title-row">
        <div class="typeTitle x-sub-color">
          <span class="iconfont">&#xe6a1;</span>
          <Select
            :value="selectedSite ? selectedSite.guid : ''"
            @input="changeSelectSiteGuid"
            style="width: 200px"
            placeholder="请选择气象站"
          >
            <Option
              v-for="el in weatherSites"
              :key="el.guid"
              :value="el.guid"
              >{{ el.name }}</Option
            >
          </Select>
        </div>
        <div class="cut-pane">
          <span
            :class="{ active: isActive === 'chart', iconfont: true }"
            @click="() => cut('chart')"
            >&#xe6a3;</span
          >
          <span
            :class="{ active: isActive === 'table', iconfont: true }"
            @click="() => cut('table')"
            >&#xe6a4;</span
          >
        </div>
      </div>
      <div class="empty-span" v-if="isEmpty">
        <img src="/imgs/empty/empty.png" />
        <span>暂无数据</span>
      </div>
      <div v-else-if="isActive === 'chart'" class="content-pane">
        <div
          class="content-pane-item"
          v-for="(item, index) in sensorList"
          :key="index"
        >
          <!-- <div>
            <img :src="item.imgUrl" alt />
            <div>
              <p>{{item.measure}}{{item.sensorCategoryUnit}}</p>
              <p>{{item.name}}</p>
            </div>
          </div>
          <div ref="soilLine" class="chart-container"></div> -->
          <img :src="item.imgUrl" />
          <p class="val">
            {{
              item.measure > Number(item.categoryMaxRange)
                ? Number(item.categoryMaxRange).toFixed(1)
                : item.measure.toFixed(1)
            }}<span>{{ item.sensorCategoryUnit }}</span>
          </p>
          <p class="data-name">
            {{ item.name
            }}<span v-if="item.sensorCategoryRemark">{{
              "(" + item.sensorCategoryRemark + ")"
            }}</span>
          </p>
          <p>{{ item.updateTime.slice(5) }}</p>
          <a
            class="chartIcon"
            title="查看近期数据曲线"
            @click="() => showChart(item)"
          >
            <img src="/imgs/iot/chartIcon.png" />
          </a>
        </div>
      </div>
      <div
        v-else-if="isActive === 'table'"
        style="margin-top: 10px"
        class="table-pane"
      >
        <Table
          :columns="listCol"
          :data="sensorList"
          :loading="listLoading"
        ></Table>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import { mapState } from "vuex";
export default {
  name: "monitor",
  props: {
    currentSites: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedSite: Object,
  },
  data() {
    return {
      isActive: "chart",
      listCol: [
        {
          title: " ",
          align: "center",
          key: "imgUrl",
          minWidth: 80,
          render: (createEle, params) => {
            return createEle("span", {
              style: {
                display: "inline-block",
                width: "27px",
                height: "27px",
                background: `url(${params.row.imgUrl}) center no-repeat`,
                "background-size": "100% 100%",
              },
            });
          },
        },
        {
          title: "监测指标",
          key: "name",
          minWidth: 120,
        },
        {
          title: "监测值",
          key: "measure",
          minWidth: 90,
          render: (c, { row }) => {
            return (
              <span>{row.measure + "" + (row.sensorCategoryUnit || "")}</span>
            );
          },
        },
        {
          title: "更新时间",
          key: "updateTime",
          minWidth: 150,
        },
        {
          title: "参考范围",
          key: "channelName",
          minWidth: 110,
          render: (c, { row }) => {
            return (
              <span>{row.categoryMinRange + "~" + row.categoryMaxRange}</span>
            );
          },
        },
        {
          title: "状态",
          key: "channelName",
          minWidth: 80,
          render: (createEle, params) => {
            switch (params.row.status) {
              case -1:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#FF0000",
                    },
                  },
                  "删除"
                );
              case 0:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#fff",
                    },
                  },
                  "未启用"
                );
              case 1:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#78BAF4",
                    },
                  },
                  "正常"
                );
              case 2:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#FCA454",
                    },
                  },
                  "无数据"
                );
              case 3:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#dddee1",
                    },
                  },
                  "设备离线"
                );
              case 4:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#e4393c",
                    },
                  },
                  "数据异常"
                );
              case 5:
                return createEle(
                  "span",
                  {
                    style: {
                      color: "#7DA900",
                    },
                  },
                  "更新超时"
                );
            }
          },
        },
      ],
      listLoading: false,
      sensorList: [],
      lineChartData: {},
      // 当前选择的气象站
      currentSiteGuid: "",
      // 是否没有气象站
      isEmpty: false,
    };
  },
  computed: {
    // ...mapState({
    //   currentSites: (state) => state.map.currentSites,
    //   selectedSite: (state) => state.map.selectedSite,
    // }),
    // 气象站数据
    weatherSites() {
      return this.currentSites.filter((item) => item.siteType == 1);
    },
  },

  methods: {
    cut(val) {
      this.isActive = val;
    },
    getSensorList(sensorGuids) {
      this.listLoading = true;
      return this.$post(this.$api.MONITOR.MONITOR_LIST, {
        pageNo: 1,
        pageSize: 9999,
        guidArr: sensorGuids.split(","),
      })
        .then((resp) => {
          this.sensorList = resp.list;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    showChart(data) {
      this.$emit("showChart", {
        ...data,
        siteInfo: { ...this.selectedSite },
        sensorList: [...this.sensorList],
      });
    },
    // 选中
    changeSelectSiteGuid(guid) {
      let site = this.weatherSites.find((item) => item.guid == guid);
      this.$emit("on-change", site);
    },
  },

  mounted() {
    if (!this.weatherSites || this.weatherSites.length == 0) {
      this.currentSiteGuid = "";
      this.isEmpty = true;
      this.sensorList = [];
    } else {
      if (this.selectedSite && this.selectedSite.siteType == 1) {
        this.getSensorList(this.selectedSite.sensorGuids);
      } else this.$emit("on-change", this.weatherSites[0]);
    }
  },
  // beforeDestroy() {
  //   this.$store.commit("setSeletedSites");
  // },

  watch: {
    weatherSites(sites) {
      if (!sites || sites.length == 0) {
        this.isEmpty = true;
        this.sensorList = [];
        return;
      }
      if (this.selectedSite && this.selectedSite.siteType == 1) {
        this.getSensorList(this.selectedSite.sensorGuids);
      } else {
        this.$store.commit("setSeletedSites", this.weatherSites[0]);
      }
    },
    selectedSite(site) {
      if (!site) this.sensorList = [];
      else if (site.siteType == 1) {
        this.getSensorList(this.selectedSite.sensorGuids);
        this.isEmpty = false;
      } else this.sensorList = [];
    },
  },
};
</script>
<style lang="less" scoped>
.moniPane {
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .typeTitle {
      display: flex;
      align-items: center;
      margin-right: 16px;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-right: 5px;
        color: #fff;
      }
    }
  }
  .cut-pane {
    height: 30px;
    span {
      display: block;
      float: left;
      padding: 3px 10px;
      border: 1px solid #0b8183;
      color: #0b8183;
      cursor: pointer;
      &:first-child {
        border-right: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
    .active {
      background: #0b8183;
      color: #fff;
    }
  }

  .content-pane {
    /* width: 470px;
    height: 600px;
    overflow: auto; */
    // width: 461px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 16px 0;
    .content-pane-item {
      width: 190px;
      background: rgba(0, 29, 34, 0.5);
      padding: 8px;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;
      // padding: 0 16px;
      /* div:first-child {
        display: flex;
        align-items: center;
        img {
          border: 1px dashed #fff;
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        div {
          p:first-child {
            color: #ff0000;
            font-weight: bold;
          }
        }
      }
      .chart-container {
        width: 200px;
        height: 120px;
      } */
      > img {
        // float: left;
        position: absolute;
        left: -8px;
        height: 80px;
      }
      p {
        padding-left: 60px;
        color: #dddddd;
        margin-bottom: 2px;
      }
      .val {
        font-size: 20px;
        color: #00ffee;
        span {
          font-size: 14px;
          margin-left: 3px;
        }
      }
      .data-name {
        span {
          font-size: 12px;
        }
      }
      .chartIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        img {
          width: 16px;
        }
      }
    }
  }
  .table-pane {
    margin: 10px 0;
  }

  .empty-span {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      width: 300px;
    }
    span {
      position: absolute;
      left: 174px;
      bottom: 58px;
      color: cornsilk;
    }
  }
}
</style>